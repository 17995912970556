import { api, Methods } from '../services/api';
import {
  CreateSubscriptionRequest,
  SubscriptionResponse,
  FetchUserProfileResponse,
  EditProfileRequest,
  UserMetaDataResponse,
  UserResponse,
  UpdatePaymentRequest,
} from '@solin-fitness/types';
import { useQuery } from 'react-query';
import { IncomingMessage } from 'http';

export interface ForgotPasswordRequest {
  email: string;
}

export interface ForgotPasswordResponse {
  status: string;
  msg: string;
}

export const createSubscription = async (
  data: CreateSubscriptionRequest,
  xPage: string,
): Promise<SubscriptionResponse> =>
  await api<SubscriptionResponse>(Methods.post, '/user/subscription', data, {
    xPage,
  });

export const fetchUserProfile = async (): Promise<FetchUserProfileResponse> =>
  await api<FetchUserProfileResponse>(Methods.get, '/user/profile');

export const fetchUserProfileServerSide = async (req: IncomingMessage) =>
  api<FetchUserProfileResponse>(
    Methods.get,
    '/user/profile',
    {},
    {
      headers: { Cookie: req.headers.cookie },
    },
  );

export const useUserProfile = (enabled = false) => {
  return useQuery('profile', fetchUserProfile, {
    cacheTime: 15 * 60 * 1000,
    enabled,
  });
};

export const editUserProfile = async (data: EditProfileRequest): Promise<any> =>
  await api(Methods.patch, '/user/profile', data);

export const fetchUserMetadata = async (): Promise<UserMetaDataResponse> =>
  await api<UserMetaDataResponse>(Methods.get, '/user/metadata');

export const cancelSubscription = async (
  subscriptionId: string,
): Promise<any> =>
  await api(Methods.post, '/user/subscription/cancel', { subscriptionId });

export const reactivateSubscription = async (
  subscriptionId: string,
): Promise<any> =>
  await api(Methods.post, '/user/subscription/reactivate', { subscriptionId });

export const fetchUserById = async (userId: number): Promise<UserResponse> =>
  api<UserResponse>(Methods.get, `/user/${userId}`).then((res) => res);

export const useUserProfileById = (userId: number) =>
  useQuery(['profile', userId], () => fetchUserById(userId), {
    cacheTime: 120 * 60 * 1000,
    retry: false,
  });

export const forgotPasswordInitiate = async (
  body: ForgotPasswordRequest,
): Promise<ForgotPasswordResponse> =>
  await api(Methods.post, '/reset/initiate', body);

export interface ResetPasswordRequest {
  password: string;
  token: string;
}

export interface ResetPasswordResponse {
  status: string;
  msg: string;
}

export const resetPassword = async (
  body: ResetPasswordRequest,
): Promise<ResetPasswordResponse> => await api(Methods.post, '/reset', body);

export const addPaymentMethod = async (
  body: UpdatePaymentRequest,
): Promise<void> => await api(Methods.post, '/user/update-payment', body);
