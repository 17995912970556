import React from 'react';
import styled from 'styled-components';
import { buildImageUrl } from 'cloudinary-build-url';
import Image from 'next/image';
import { Gravity, RESIZE_TYPES } from '@cld-apis/utils';

interface IProps {
  imageSize?: { width: number; height: number };
  firstName: string;
  lastName: string;
  profilePicture: string | null;
  userId: number;
  noBorder?: boolean;
}

const ProfileImage = ({
  firstName,
  lastName,
  profilePicture,
  imageSize = { width: 36, height: 36 },
  noBorder,
}: IProps) => {
  const initials = `${firstName ? firstName.charAt(0) : ''}${
    lastName ? lastName.charAt(0) : ''
  }`;

  const width = imageSize.width + 'px';
  const height = imageSize.height + 'px';
  const styles = {
    '--width': width,
    '--height': height,
    '--border': noBorder ? 'none' : undefined,
  };

  const imageUrl = profilePicture
    ? buildImageUrl(profilePicture, {
        transformations: {
          resize: {
            type: RESIZE_TYPES.THUMBNAIL,
            width: 300,
            height: 300,
          },
          gravity: Gravity.FaceCenter,
          radius: 'max',
        },
      })
    : null;

  return profilePicture ? (
    <Wrapper style={styles}>
      <ImageFluid
        src={imageUrl}
        width={100}
        height={100}
        alt="Avatar picture"
      />
    </Wrapper>
  ) : (
    <InitialsWrapper style={styles}>
      <Initials>{initials}</Initials>
    </InitialsWrapper>
  );
};

const Wrapper = styled.div`
  width: var(--width);
  height: var(--width);
  border: var(--border, 2px solid rgba(0, 0, 0, 0.2));
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
`;

const InitialsWrapper = styled(Wrapper)`
  background: var(--color-primary-300);
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding adding to counter bottom spacing from font */
  padding-top: 2px;
`;

const ImageFluid = styled(Image)`
  width: 100%;
  height: auto;
`;

const Initials = styled.p`
  font-family: 'HKGrotesk';
  font-size: var(--font-size-body-01);
  letter-spacing: var(--letter-spacing-sm);
  color: var(--color-white);
  text-transform: uppercase;
`;

export default ProfileImage;
