import { createMedia } from '@artsy/fresnel';
import { BREAKPOINTS } from './constants/theme';

const AppMedia = createMedia({
  breakpoints: {
    sm: 0,
    md: BREAKPOINTS.phoneMax,
    lg: BREAKPOINTS.tabletMax,
    xl: BREAKPOINTS.laptopMax,
  },
});

// Make styles for injection into the header of the page
export const mediaStyles = AppMedia.createMediaStyle();

export const { Media, MediaContextProvider } = AppMedia;
