import { DateTime } from 'luxon';

/**
 * Return first and last date of week interval
 * @param curr current date
 * @param interval week interval
 */
export const getWeekInterval = (curr: Date, interval = 0): Date[] => {
  const first = curr.getDate() - curr.getDay() + 1;
  const last = first + 6;
  const addedInterval = interval * 7;
  const firstDay = new Date(
    new Date(curr.setDate(first + addedInterval)).setHours(0, 0, 0, 0),
  );
  const lastDay = new Date(
    new Date(curr.setDate(last + addedInterval)).setHours(23, 59, 59, 999),
  );
  return [firstDay, lastDay];
};

export const dateNth = (d: number) => {
  if (d > 3 && d < 21) {
    return 'th';
  }
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const formatDate = (date: Date) =>
  `${DateTime.fromJSDate(date).toFormat('cccc, LLLL d')}${dateNth(
    date.getDate(),
  )} at  ${DateTime.fromJSDate(new Date(date)).toFormat('hh:mm')}${
    date.getHours() >= 12 ? 'pm' : 'am'
  }`;

export const formatDateSimple = (date: Date) =>
  `${DateTime.fromJSDate(date).toFormat('ccc, LLL d')}${dateNth(
    date.getDate(),
  )} at  ${DateTime.fromJSDate(new Date(date)).toFormat('hh:mm')}${
    date.getHours() >= 12 ? 'pm' : 'am'
  }`;

export const getDay = (date: Date, daysAway = 0): Date => {
  const temp = new Date(date);
  const day = temp.getDate() - temp.getDay() + daysAway;
  return new Date(temp.setDate(day));
};

export const getStartOfWeek = (): Date =>
  DateTime.fromJSDate(new Date()).startOf('week').toJSDate();

/**
 * get start time of a calendar item
 * @param hour
 * @param minute
 * @param time
 * @returns
 */
export const getStartTime = (
  hour: number,
  minute: number,
  time: string,
): string => {
  let adjustedHour = hour;
  if (hour === 0) {
    adjustedHour = 12;
  }
  if (minute === 0) {
    return `${adjustedHour}${time}`;
  }

  if (minute < 10) {
    return `${adjustedHour}:0${minute}${time}`;
  }
  return `${adjustedHour}:${minute}${time}`;
};

export const getTimeBetween = (target: Date) => {
  const dateFuture = target.getTime();
  const dateNow = new Date().getTime();

  const seconds = Math.floor((dateFuture - dateNow) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (minutes < 60) {
    return `in ${minutes} minutes`;
  } else if (hours < 24) {
    return `in ${hours} hours`;
  } else {
    return `in ${days} days`;
  }
};

export const getTimeBetweenString = (target: Date): string[] => {
  const dateFuture = target.getTime();
  const dateNow = new Date().getTime();

  const seconds = Math.floor((dateFuture - dateNow) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (minutes < 60) {
    return [minutes.toString(), 'min to class'];
  } else if (hours < 24) {
    return [hours.toString(), 'hr to class'];
  } else {
    return [days.toString(), 'days to class'];
  }
};

export const getCurrentDay = () => {
  const curr = new Date();
  return curr.getDay() === 0 ? 6 : curr.getDay() - 1;
};

export const getTimeSince = (date: Date) => {
  const target = date.getTime();
  const current = new Date().getTime();

  const seconds = Math.floor((current - target) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days >= 1) {
    return `${days}d`;
  } else if (hours >= 1) {
    return `${hours}h`;
  } else if (minutes >= 1) {
    return `${minutes}m`;
  } else {
    return `${seconds}s`;
  }
};
